import React from "react";
import LandingBg from "../../assets/img/landingBg.jpg";
import LogoLanding from "../../assets/img/logoLanding.png";
import "./authLayout.scss";

const style = {
  root: {
    justifyContent: "center",
  },
  width: "100vw",
  height: "100vh",
  position: "fixed",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  top: 0,
  left: 0,
  zIndex: -1,
  backgroundImage: `url(${LandingBg})`,
  backgroundSize: "cover",
};

export function getEnvColor () {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if(env === 'test') return '#34ace0'
  else if(env === 'staging') return '#8e44ad'

  return null;
}

const AuthLayout = (props) => {
  
  return (
    <div className="auth-pages-layout" style={style}>
      <div className="auth-pages-top-bar" style={{backgroundColor: getEnvColor() || '#fff'}}>
        <img src={LogoLanding} alt="logo" />
      </div>
      {props.children}
    </div>
  );
}

export default AuthLayout;


