import React, { useContext, useState } from "react";
import { AuthContext } from '../../auth/AuthContext';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import PublishNewsScreenIcon from '../../assets/img/News/draftScreenIcon.svg';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import ConfirmModal from "../Modal/ConfirmModal";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TableRow from "@material-ui/core/TableRow";
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import { Paper } from "@material-ui/core";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import "./newsTable.scss";
import Moment from "react-moment";
import { deleteNewsById, updateNewsById } from "../../service/news";
import { withSnackbar } from "notistack";

// const TextOnlyTooltip = withStyles({
//   tooltip: {
//     fontSize: '15px'
//   }
// })(Tooltip);


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '18px',
    padding: '16px 8px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  body: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    color: '#323232',
    padding: '4px 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F9F6F6",
      },
    },
    [theme.breakpoints.down('sm')]: {
      "&:hover": {
        backgroundColor: "#DDDADA",
      },
    },
  },
}))(TableRow);

const useStyles = new makeStyles({
  table: {
    minWidth: 650
  }
});

const newsTable = ({ data, enqueueSnackbar, className, onChange, mode }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [modalPublish, setModalPublish] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const { onlyAdmin } = useContext(AuthContext);

  const onlyFetchMode = () => {
    return mode === 'fetch';
  }

  const handleDelete = async () => {
    const news = data[activeIndex];
    try {
      await deleteNewsById(news._id);
      onChange(activeIndex, 'delete');
      enqueueSnackbar("News Deleted", { variant: 'success' });
    } catch (err) {
      callbackError(err)
    }
  }

  const handlePublish = async () => {
    const news = data[activeIndex];

    try {
      const payload = { status: news.status === 'published' ? "draft" : 'published' }
      await updateNewsById(news._id, payload);
      onChange(activeIndex, 'publish');
    } catch (err) {
      callbackError(err);
    }
  }

  const callbackError = ({ response }) => {
    if (response) {
      enqueueSnackbar(response.data.message, { variant: 'error' });
    } else {
      enqueueSnackbar("Unexpected Error Occured!", { variant: 'error' });
    }
  }

  const getPriority = priorNum => {
    switch (priorNum) {
      case 1:
        return "Alert";
      case 2:
        return "High";
      case 3:
        return "Medium";
      default:
        return "Low";
    }
  };

  const renderStatus = status => (
    status === "published" ?
      <VisibilityOutlinedIcon className="visibility-icon-draftnews" color="action" /> :
      <VisibilityOffOutlinedIcon className="visibility-icon-draftnews" color="action" />
  )

  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className={className}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {(onlyAdmin() && !onlyFetchMode()) && <StyledTableCell align="left">User</StyledTableCell>}
              <StyledTableCell align="left">Title</StyledTableCell>
              {!onlyFetchMode() && <StyledTableCell align="left">Date</StyledTableCell>}
              {onlyFetchMode() && <StyledTableCell align="center">Fetched Date</StyledTableCell>}
              <StyledTableCell align="left">Sources</StyledTableCell>

              {onlyFetchMode() && 
              <StyledTableCell align="left">
                Keywords Matched
              </StyledTableCell>}
              
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Priority</StyledTableCell>
              <StyledTableCell align="left">
                <div className="actionstate-headertable-wapper">
                  <span>
                    Actions
                  </span>
                  <span>
                    State
                  </span>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={row._id}>
                {/* <Hidden smDown> */}
                <StyledTableRow>
                  {(onlyAdmin() && !onlyFetchMode()) &&
                    <StyledTableCell align="left">
                      <div className="title-wrapper">
                        {row.createdBy ? `${row.createdBy.name.first} ${row.createdBy.name.last}` : "System"}
                      </div>
                    </StyledTableCell>}

                  <StyledTableCell align="left">
                    <div className="title-wrapper">
                      {row.title}
                    </div>
                  </StyledTableCell>

                  {!onlyFetchMode() && <StyledTableCell align="left">
                    <Moment format="DD/MM/YYYY" unix>{row.createdAt}</Moment>
                  </StyledTableCell>}

                  {onlyFetchMode() && <StyledTableCell align="center">
                    <Moment format="DD/MM/YYYY" unix>{row.fetchedDate}</Moment>
                  </StyledTableCell>}

                  <StyledTableCell className="permissionsCell" align="left">
                    <div className="src-keyword-wrapper">
                      {row.sources.map(({ link, name }, index) => (
                        <div key={index} className="sources-wrapper-draftnews">
                          <div className="sourcelink-icon-wrapper">
                            <LinkIcon color="secondary" className="link-icon-draftnews" />
                            {/* <TextOnlyTooltip title={link} placement="right"> */}
                            <a target="_blank" href={link}  rel="noopener noreferrer" className="source-name-draftnews">{name ? name : link}</a>
                            {/* </TextOnlyTooltip> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </StyledTableCell>

                  {onlyFetchMode() && <StyledTableCell className="permissionsCell" align="left">
                    <div className="src-keyword-wrapper">
                      {/* {row.keywordsMatched.map((word, index) => <span key={index}>{word}-</span>)} */}
                    </div>
                  </StyledTableCell>}

                  <StyledTableCell className="permissionsCell" align="center">
                    {row.status}
                  </StyledTableCell>

                  <StyledTableCell className="permissionsCell" align="center">
                    {getPriority(row.priority)}
                  </StyledTableCell>

                  <StyledTableCell align="center">

                    {/* // editor can not do any actions on Admins
                      // {!(row.role === 'admin' && !onlyAdmin()) &&  */}
                    <div className="edit-delete-btn-wrapper">
                      {/* <Link to={`/news/edit/${row.id}`}> */}
                      <Link to={`/news/edit/${row._id}`}>
                        <IconButton className="buttonUserAction">
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Link>

                      <IconButton
                        className="buttonUserAction"
                        onClick={() => {
                          setDeleteConfirm(true);
                          setActiveIndex(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {/* aca va } */}

                      {/* </StyledTableCell>
                    <StyledTableCell className="permissionsCell" align="center">
                      <Grid item md={4} xs={12}> */}
                      <div className="visibility-switch-rapper">
                        <FormControlLabel
                          control={<Switch
                            onChange={() => {
                              setActiveIndex(index);
                              setModalPublish(true);
                            }}
                            checked={row.status === 'published' ? true : false}
                            name="status"
                          />}
                          labelPlacement="start"
                        />
                        {renderStatus(index)}
                      </div>
                    </div>
                    {/* </Grid> */}
                  </StyledTableCell>
                </StyledTableRow>
                {/* </Hidden> */}

                {/* <Hidden mdUp>
                  <StyledTableRow onClick={() => handleEdit()}>
                    <StyledTableRow onClick={() => handleEdit(row)}> 
                    <StyledTableCell align="left">
                      {`${row.name.first} ${row.name.last}`} 
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ChevronRightIcon></ChevronRightIcon>
                    </StyledTableCell>
                  </StyledTableRow>
                </Hidden> */}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {Boolean(data.length) && <ConfirmModal
        icon={PublishNewsScreenIcon}
        title={`${data[activeIndex].status === 'published' ? 'Unpublish' : 'Publish'} News`}
        message={`Are you sure you want to ${data[activeIndex].status === 'published' ? 'Unpublish' : 'Publish'} this draft?`}
        action={data[activeIndex].status === 'published' ? 'Unpublish' : 'Publish'}
        open={modalPublish}
        handleClose={() => setModalPublish(false)}
        handleConfirm={handlePublish}
      />}

      <ConfirmModal
        icon={PublishNewsScreenIcon}
        title="Delete Draft"
        message="Are you sure you want to delete this draft?"
        action="Delete"
        open={deleteConfirm}
        handleClose={() => setDeleteConfirm(false)}
        handleConfirm={handleDelete}
      />

    </React.Fragment>
  );
}

export default withSnackbar(newsTable);