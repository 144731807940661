import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ChevronLeft } from '@material-ui/icons';
import AddCompanyImgIcon from '../../../assets/img/addCompanyIcon.svg';
import AdminBase from './AdminBase';
import FormBase from './FormBase';
import { uploadFile } from '../../../service/files';
import { toggleLoading } from '../../../redux/app/actions';
import { createNewCompany, editCompany, getAllCompany } from '../../../service/companies';
import {addCompany, setCompanyQuery, updateCompany} from '../../../redux/companies/actions';
import {orderBy} from 'lodash';

const CompanyAdd = ({ enqueueSnackbar, dispatchToggleLoading, dispatchAddCompany, dispatchSetCompanyQuery, activeCompany, ...props }) => {

  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [alias, setAlias] = useState('');
  const [companyHeadquarters, setCompanyHeadquarters] = useState('');
  const [type, setType] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(true);
  const [parentCompany, setParentCompany] = useState(null);

  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);

  const [isValid, setValidity] = useState(false);

  const { companyId } = useParams();
  

  useEffect(() => {
    if (companyId) {
      activeCompany ? initExisting(activeCompany) : goBack();
    } 
    searchCompanies();

    return () => {
      if(props.companyQuery !== '' && props.companyQuery !== null){
        dispatchSetCompanyQuery();
        return getAllCompany();
      }
    }
  }, []);

  const searchCompanies = () => {
    setLoadingCompanies(true);
    getAllCompany()
      .then((data)=>{
        setLoadingCompanies(false);
        // if there is a parentCompany already selected, this is needed for populating the field.
        setAllCompanies(parentCompany ? [parentCompany, ...data]: data)
      })
      .catch(({response})=>{
        setLoadingCompanies(false);
        if(response){
          enqueueSnackbar(response.data.message, {variant: 'error'})
        }else
          enqueueSnackbar("Unexpected error occured while getting companies!", {variant: 'error'})
      })
  }

  const initExisting = (company) => {
    setCompanyHeadquarters(company.headquarters || '');
    setCompanyName(company.name);
    setWebsite(company.website || '');
    setAlias(company.alias || '');
    setType({_id: company.type, name: company.type});
    setStatus(company.status === 'active' ? true : false);
    
    if(company.parentCompany){
      // needed for populating the field
      setAllCompanies([company.parentCompany, ...allCompanies]);
      setParentCompany(company.parentCompany)
    }

    if (company.logo) setFiles([{ name: 'logo', link: company.logo }])
  }

  const deleteFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  const handleFileChange = ({ target }) => {
    const { files } = target;
    if (!files.length) return;

    const formData = new FormData();
    const tempState = [];
    for (let f of files) {
      tempState.push({ name: f.name, link: '' })
      formData.append('file', f)
    }
    setFiles(tempState);
    setUploading(true)

    uploadFile(formData)
      .then(({ data }) => {
        tempState.forEach((f, i) => f.link = data[i]);
        setFiles(tempState);
        setUploading(false)
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  };
  const formComponents = [
    {
      id: 'name',
      name: 'name',
      placeHolder: "Company Name",
      onChange: compname => setCompanyName(compname),
      type: 'input',
      key: 'name',
      value: companyName,
      required: true,
      sm: 6
    },
    {
      placeHolder: "Website",
      onChange: website => setWebsite(website),
      type: 'input',
      key: 'website',
      value: website,
      icon: 'language',
      sm: 6
    },
    {
      placeHolder: "Alias",
      onChange: alias => setAlias(alias),
      type: 'input',
      key: 'alias',
      value: alias,
      sm: 6
    },
    {
      placeHolder: "Company Headquarters",
      onChange: hq => setCompanyHeadquarters(hq),
      type: 'input',
      key: 'headquarters',
      value: companyHeadquarters,
      sm: 6
    },
    {
      placeHolder: "Company Type",
      onChange: (e, value) => setType(value),
      type: 'select',
      id: 'type',
      required: true,
      value: type,
      options: orderBy([
        { _id: 'Pharmaceutical', name: 'Pharmaceutical' },
        { _id: 'Biotech', name: 'Biotech' },
        { _id: 'Academic Institution', name: 'Academic Institution' },
        { _id: 'Med Tech', name: 'Med Tech' },
        { _id: 'Others', name: 'Others' }
      ], ['name'], ['asc']),
      sm: 6
    },
    {
      id: "parentCompany",
      type: 'select',
      value: parentCompany,
      onChange: (e, selected) => {
        setParentCompany(selected);
        console.log(selected)
      },
      placeHolder: "Parent Company",
      options: allCompanies,
      sm: 6,
      loading: loadingCompanies,
      handleSearch: (value) => {
        dispatchSetCompanyQuery(value);
        return searchCompanies();
      }
    },
    {
      placeHolder: "Choose file to upload",
      onChange: e => handleFileChange(e),
      type: 'fileUploader',
      id: "file",
      name: "file",
      uploadStatus: uploading,
      files: files,
      onClick: index => deleteFile(index),
      sm: 6
    },
    {
      value: status,
      name: "status",
      label: 'Status',
      type: 'switch',
      onChange: e => handleStatusChange(e),
      render: () => renderStatus(),
      sm: 6
    }
  ];
  
  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const admTitleComponent = (
    <Link to="#" onClick={goBack}>
      <h2><ChevronLeft /> {companyId ? 'Update' : 'Add'} Company</h2>
    </Link>
  );

  const buttons = [
    {
      onClick: () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      type: 'submit',
      text: companyId ? 'update' : 'add',
      disabled: !isValid
    },
  ]

  const handleCancelClick = () => {
    goBack();
  };

  const handleCompanyAdd = () => {
    dispatchToggleLoading();

    const company = buildCompany();

    createNewCompany(company, (newCompany) => {
      dispatchToggleLoading();
      // dispatchAddCompany(newCompany)
      enqueueSnackbar("Company Added!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      dispatchToggleLoading();
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while creating the company.`, {
          variant: "error",
        });
      }
    })
  };

  const handleCompanyUpdate = () => {
    dispatchToggleLoading();
    const company = buildCompany();
    editCompany(companyId, company)
      .then(({ data }) => {
        // props.dispatchUpdateCompany(data);
        goBack();
      })
      .catch(({ response }) => {
        if (response) {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`Error occurred while creating the company.`, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        dispatchToggleLoading();
      })
  }

  const buildCompany = () => {
    const company = {};
    const inputs = formComponents.filter(comp => comp.type === 'input');
    inputs.forEach((input) => {
      company[input.key] = input.value;
    });
    company.type = type._id;
    company.status = status ? 'active' : 'inactive';
    company.logo = files.length ? files[0].link : "";
    if(parentCompany) company.parentCompany = parentCompany._id;
    return company;
  };

  return (
    <AdminBase
      titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}
    >
      <FormBase
        title={`Please Complete the Fields to ${companyId ? 'Update the' : 'Add a New'}  Company`}
        onSubmit={companyId ? handleCompanyUpdate : handleCompanyAdd}
        formComponents={formComponents}
        buttons={buttons}
        iconImage={AddCompanyImgIcon}
        onChangeValidity={setValidity}
        validateOnMount={true}
      />

    </AdminBase>
  )
}

const mapStateToProps = (state) => {
  const { active, query } = state.companies;
  return {
    activeCompany: active,
    companyQuery: query
  };
};

const mapActionsToProps = {
  dispatchToggleLoading: toggleLoading,
  dispatchAddCompany: addCompany,
  dispatchUpdateCompany: updateCompany,
  dispatchSetCompanyQuery: setCompanyQuery
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(CompanyAdd));