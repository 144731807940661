import React, {useEffect, useState} from 'react';
import { Formik } from "formik";
import { isEmpty } from "lodash";

import {
  FormControl,
  InputLabel,
  Button,
  Grid,
  FormControlLabel,
  Paper,
  Switch,
  FilledInput,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  InputBase
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import LanguageIcon from '@material-ui/icons/Language';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {Editor} from "@tinymce/tinymce-react";

import {Input, ComboBox, ComboBoxSimple, RelatedFields} from '../../common';
import InputsRL from './inputRL';
import DynamicField from "./DynamicField";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: '1% 0'
  },
  FormControl: {
    margin: '0 35px'
  },
  negativeButton: {
    marginRight: '20px'
  },
  formMargin30: {
    padding: '0 26%'
  },
  formMargin38: {
    padding: '0 38%'
  },
  uploadButton: {
    textTransform: 'none',
    marginTop: '10px',
    padding: "10px",
    borderRadius: '4px',
    background: '#CCC4C4',
    cursor: 'pointer'
  },
  fileIconStyle: {
    fontSize: '13px',
    marginBottom: '2px'
  },
  labelsPlaceHolders: {
    marginLeft: '20px'
  },
  labelDynamicField: {
    marginLeft: '10px',
    marginTop: '10px'
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    color: '#8A8A8A'
  },
  switchBackground: {
    width: '100%',
    padding: '5px',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-end',
    background: '#F4F4F4'
  },
  switchWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    verticalAlign: 'center',
  },
  formControlsFilledInput: {
    width: '100%'
  },
  chilFilledInput: {
    background: '#F4F4F4',
    width: '100%'
  },
  lineSeparator: {
    textAlign: 'center',
    color: '#CCC4C4',
    fontWeight: '700'
  },
  webSiteIcon: { color: "#4D4F54" },
  input: {
    root: {
      height: '154px',
      backgroundColor: 'red'
    }
  }
});

const FormBase = ({ onChangeValidity, validateOnMount = false, ...props }) => {

  const [initialValues, setInitialValues] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    root,
    formMargin30,
    formMargin38,
    negativeButton,
    chipInputContainer,
    chilFilledInput
  } = useStyles();

  //TODO() change this by yup
  const validate = (values) => {
    const errors = {};
    let i = 0, len = formComponents.length;
    while (i < len) {
      const component = formComponents[i];
      if(component.type === 'input' &&
        component.required &&
        (!values[component.id] || !values[component.id].trim())) errors[component.id] = "Field Required";
      if((component.type === 'select' || component.type === 'datetime' || component.type === 'chipInput') &&
        component.required &&
        !values[component.id]) errors[component.id] = "Field Required";
      i++;
    }
    return errors;
  }

  const renderField = (params, formik) => {

    const {
      formControlsFilledInput,
      switchWrapper,
      formControl,
      labelsPlaceHolders,
      labelDynamicField,
      fileIconStyle,
      uploadButton,
      sectionTitle,
      lineSeparator,
      switchBackground,
      webSiteIcon,
      input
    } = useStyles();
    const {
      onChange,
      value,
      icon,
      placeHolder,
      toUpperCase,
      required,
      id,
      name,
      title,
      sm,
      options,
      label,
      switchColor,
      style,
      handleSearch,
      loading,
      relatedFields,
      datetime
    } = params;

    /*const checkValidity = (valid) => {
      return onChangeValidity? onChangeValidity(valid !== undefined? valid:formik.isValid):false;
    };*/

    const error = (isSubmitted || formik.touched[id]) && Boolean(formik.errors[id]);
    const helperText = (isSubmitted || formik.touched[id]) && formik.errors[id];
    switch(params.type) {
      case 'input':
        return (
          <Grid item xs={12} sm={sm} key={placeHolder}>
            {title && title === "space" ? 
              <label className={sectionTitle}>&nbsp;</label> : 
              <label className={sectionTitle}>{title}</label>}
            <FormControl className={formControlsFilledInput} variant="filled" error={error}>
              <Input
                id={id}
                name={name}
                onChange={e => {
                  formik.handleChange(e);
                  onChange(e.currentTarget.value);
                  //checkValidity();
                }}
                onBlur={e => {
                  formik.handleBlur(e);
                  //checkValidity();
                }}
                value={value}
                placeHolder={placeHolder}
                required={Boolean(required)}
                style={input.root}
                startAdornment={<InputAdornment position="end">
                  {icon && icon === "language" ? <LanguageIcon className={webSiteIcon} /> : <></>}
                </InputAdornment>}
                type={"text"}
                helperText={helperText} />
            </FormControl>
          </Grid>
        );
      case 'line': 
        return <Grid item xs={12} sm={sm} key={id}>
          <FormControl fullWidth className={formControl}>
          <span className={lineSeparator}>________________</span>
          </FormControl>
        </Grid>;
      case 'select':
        let componentRenderized;
        if (placeHolder === 'invisible') {
          componentRenderized = <Grid item xs={12} sm={sm}>
            <FormControl fullWidth className={formControl}>
              <InputLabel className={labelsPlaceHolders}></InputLabel>
              <InputBase />
            </FormControl>
          </Grid>;
        } else {
          componentRenderized = <Grid item xs={12} sm={sm} key={id}>
          {title && title === "space" ? 
            <label className={sectionTitle}>&nbsp;</label> : 
            <label className={sectionTitle}>{title}</label>}
            <FormControl fullWidth className={formControl} error={formik.touched[id] && Boolean(formik.errors[id])}>
              <ComboBoxSimple
                required={required}
                options={options}
                id={id}
                required={Boolean(required)}
                name={name}
                value={value}
                fullWidth
                style={style}
                placeHolder={`${placeHolder}${required? "*":""}`}
                handleSearch={handleSearch}
                loading={loading}
                handleMyChange={(e,v,r) => {
                  formik.setFieldValue(id,v);
                  onChange(e,v,r);
                  //checkValidity();
                }}
                onBlur={e => {
                  formik.handleBlur(e);
                  //checkValidity();
                }}
                error={error}
                helperText={helperText}
              />
            </FormControl>
          </Grid>;
        }
          
        return componentRenderized;
      case 'chipInput':
        return <Grid item xs={12} sm={sm} key={id} className={chipInputContainer}>
          <FormControl fullWidth className={formControl}>
            <label className={sectionTitle}></label >
            <ChipInput
              id={id}
              name={name}
              required={Boolean(required)}
              className={chilFilledInput}
              value={value}
              placeholder={`${placeHolder}${required? "*":""}`}
              onAdd={chip => {
                formik.setFieldValue(id,chip);
                params.handleAddChip(chip);
                //checkValidity();
              }}
              onBlur={e => {
                formik.handleBlur(e);
                //checkValidity();
              }}
              error={error}
              helperText={helperText}
              onDelete={(chip, index) => params.handleDeleteChip(chip, index)} />
          </FormControl>
        </Grid>;
      case 'comboSelect':
        return (
          <Grid item xs={12} sm={sm} key={id}>
            {title && title === "space" ? 
              <label className={sectionTitle}>&nbsp;</label> : 
              <label className={sectionTitle}>{title}</label>}
            <ComboBox
              options={options}
              id={id}
              value={value}
              fullWidth
              handleMyChange={onChange}
              className="combo-boxes"
              label={placeHolder}
              handleSearch={handleSearch}
              loading={loading}
            />
          </Grid>
        );
      case 'switch':
        return (
          <Grid item xs={12} sm={sm} className={switchWrapper}>
            <div className={params.style === 'backgroundGray' ? switchBackground : null}>
              <FormControlLabel
                control={<Switch
                  color={switchColor}
                  onChange={onChange}
                  checked={value}
                  name={name}
                />}
                labelPlacement="start"
                label={label}
              />
              {params.render()}
            </div>
          </Grid>
        );
      case 'fileUploader':
        const { uploadStatus, files, onClick } = params;
        return (
          <Grid item xs={12} sm={sm} key={placeHolder}>
            {title && title === "space" ? 
              <label className={sectionTitle}>&nbsp;</label> : 
              <label className={sectionTitle}>{title}</label>
            }
            <FormControl fullWidth className={formControl}>
              <InputLabel className={labelsPlaceHolders} htmlFor="file">
                {placeHolder}
              </InputLabel>
              <input
                style={{ display: 'none' }}
                id={id}
                name={name}
                type="file"
                accept="image/*"
                disabled={uploadStatus}
                multiple
                onChange={onChange}
              />
              <FilledInput
                color="primary"
                id="filled-adornment-password"
                type={'text'}
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    {uploadStatus ?
                      <CircularProgress color="inherit" size={20}></CircularProgress> :
                      <label className={uploadButton} htmlFor="file">
                        Browse
                    </label>}
                  </InputAdornment>
                }
              />
              {Boolean(files.length) &&
                files.map((file, index) => (
                  <FormHelperText key={index}>
                    <ImageIcon className={fileIconStyle} />
                    &nbsp;
                    {file.name}
                    <HighlightOffIcon
                      onClick={() => onClick(index)}
                      className={fileIconStyle}
                    />
                  </FormHelperText>
                ))
              }
            </FormControl>
          </Grid>
        );
      case 'dynamicsCombo':
        return <Grid item xs={12} sm={sm} key={placeHolder}>
          <InputsRL></InputsRL>
        </Grid>;
      case 'dynamicField':
        return <Grid item xs={12} sm={sm} key={placeHolder}>
          <InputLabel className={labelDynamicField} htmlFor={id}>
            <b>{placeHolder}</b>
          </InputLabel>
          <DynamicField id={id} name={name} fields={options} onChangeValues={onChange}/>
        </Grid>;
      case 'relatedClientTaDisease':
        const config = {};
        if(relatedFields && relatedFields.client){
          config['client'] = {
            variant: 'outlined',
            breakpoints: {
              md: sm,
              sm: sm
            },
            placeHolder: toUpperCase? 'CLIENT':null
          };
        }
        if(relatedFields && relatedFields.therapeuticArea){
          config['therapeuticArea'] = {
            variant: 'outlined',
            breakpoints: {
              md: sm,
              sm: sm
            },
            placeHolder: toUpperCase? 'THERAPEUTIC AREA':null
          };
        }
        if(relatedFields && relatedFields.disease){
          config['disease'] = {
            variant: 'outlined',
            breakpoints: {
              md: sm,
              sm: sm
            },
            placeHolder: toUpperCase? 'DISEASE':null
          };
        }
        if(relatedFields && relatedFields.compound){
          config['compound'] = {
            variant: 'outlined',
            breakpoints: {
              md: sm,
              sm: sm
            },
            placeHolder: toUpperCase? 'COMPOUND':null,
            options: relatedFields.compound && relatedFields.compound.options? relatedFields.compound.options:false,
            multiple: relatedFields.compound.multiple? relatedFields.compound.multiple: true
          };
        }
        return <RelatedFields config={config} history={props.history} />;
      case 'date':
        return (
          <Grid item xs={12} sm={sm} key={placeHolder}>
            <b className="atacana-label">{placeHolder}</b>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="none"
              id="date-picker-inline"
              label={label}
              value={value}
              onChange={(newDate) => onChange(newDate)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        );
      case 'datetime':
        return (
          <Grid item xs={12} sm={sm} key={placeHolder}>
            <FormControl error={error} >
              <KeyboardDateTimePicker
                id={id}
                name={name}
                required={Boolean(required)}
                variant="inline"
                label={placeHolder}
                format="DD-MM-yyyy HH:mm:ss"
                margin="none"
                value={value}
                onChange={(newDate) => {
                  formik.setFieldValue(id, newDate);
                  onChange(newDate);
                  //checkValidity();
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={datetime && datetime.minDate? datetime.minDate:false}
                minDateMessage={datetime && datetime.invalidMinDate? datetime.invalidMinDate:"Invalid Min Date"}
                onBlur={e => {
                  formik.handleBlur(e);
                  //checkValidity();
                }}
                error={error}
              />
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </Grid>
        );
      case 'rich-textarea':
        return (
          <Grid item xs={12} sm={sm} key={placeHolder}>
            <FormControl style={{ minHeight: '400px' }} fullWidth error={error} >
              <b className="atacana-label">{placeHolder}</b>
              <Editor
                id={id}
                name={name}
                placeholder={`${placeHolder}${required? "*":""}`}
                value={value}
                required={Boolean(required)}
                apiKey="8i5j7zmlxbq6qi5g9bttji7n9xfltvmv7pf9dcw50vi549kk"
                init={{
                  height: 400,
                  menubar: false,
                  content_style: "p {margin: 0px}",
                  plugins: [
                    'lists link image',
                  ],
                  toolbar:
                  // eslint-disable-next-line no-multi-str
                    'undo redo | formatselect | bold italic underline forecolor | \
                     bullist numlist | image link'
                }}
                onEditorChange={(text) => onChange(text)}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>
        )
      default:
        return;
    }
  }

  const {
    formComponents,
    iconImage,
    title,
    buttons
  } = props;

  useEffect(()=>{
    const init = {};
    for(const comp of formComponents){
      if(comp.name) init[comp.name] = comp.value;
      else if(comp.id) init[comp.id] = comp.value;
    }
    setInitialValues(init);
  },[formComponents]);

  const OnRenderedComponent = ({formik}) => {
    useEffect(()=>{
      formik.validateForm().then(data => {
        onChangeValidity(isEmpty(data));
      });
    },[initialValues]);
    return null;
  }

  return (
    <div className={root}>
      <Paper>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnMount={validateOnMount}
          enableReinitialize
          validateOnChange
          onSubmit={(values, actions, ...rest) => {
            //actions.setSubmitting(false);
            if (props.onSubmit) props.onSubmit({values,actions,...rest});
          }}
        >
          {formik => (
            <form onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
              setIsSubmitted(true);
            }}>
              <OnRenderedComponent formik={formik}/>
              <Grid className={props.formMargin? props.formMargin:formComponents[0].sm === 6 ? formMargin30 : formMargin38} container spacing={3}>
                {title && <Grid item xs={12} sm={12}>{/* Title */}
                  <div className="form-icon-title-container">
                    <img
                      src={iconImage}
                      className="form-icon-image"
                      alt="administration icon"
                    />
                    <span className="form-title">
                      {title}
                    </span>
                  </div>
                </Grid>}
                <Grid item xs={12} sm={12} container spacing={1}>{/* BEGINING of 1st Column */}
                  {
                    formComponents.map((component, index) => <React.Fragment key={index}>{renderField(component, formik)}</React.Fragment>)
                  }
                </Grid>
                {buttons && buttons.length > 0 && <Grid item xs={12} sm={12}>{ /* buttons */}
                  <div
                    className="buttons-container"
                  >
                    {buttons.map(button => (
                      <Button
                        key={button.text}
                        variant={button.variant}
                        type={button.type ? button.type : 'button'}
                        disabled={button.disabled}
                        color="secondary"
                        onClick={(e) => {
                          if (button.onClick) button.onClick(e);
                        }}
                        className={button.text === 'cancel' ? negativeButton : null}>
                        {button.text}
                      </Button>))}
                  </div>
                </Grid>}
              </Grid>
            </form>)}
        </Formik>
      </Paper>
    </div>
  )
}

export default FormBase;