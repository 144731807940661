import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import { AddCircle, Edit, DeleteOutline, SaveAltRounded } from '@material-ui/icons';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";
import { withSnackbar } from "notistack";

import './styles.scss';
import { ADMINISTRATION, KEYWORD } from '../../../constants/routes';
import AdminBase from "./AdminBase";
import GenericTable from "../../Table/genericTable";
import PublishNewsScreenIcon from "../../../assets/img/News/draftScreenIcon.svg";
import ConfirmModal from "../../Modal/ConfirmModal";
import { deleteKeywordById, editKeyword, getAllKeywords } from "../../../service/keywords";
import { deleteKeyword, setActiveKeyword, setKeywordQuery, updateKeyword, updateStatusKeyword } from "../../../redux/keywords/actions";
import { importFile } from '../../../service/files';
import ImportButton from '../../common/ImportButton';
import templateLink from "../../../assets/templates/Keywords_Template_v2.xlsx";

const Keyword = ({ enqueueSnackbar, ...props }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { loading, allKeywords, total } = useSelector((state) => state.keywords);

  const dispatch = useDispatch();
  const { path } = useRouteMatch();


  useEffect(()=>{
    dispatch(setKeywordQuery(null))
    loadKeywords();
  }, []);

  const loadKeywords = () => {
    getAllKeywords()
      .then((data)=>{})
      .catch(({response})=>{
        if(response){
          enqueueSnackbar(response.data.message, {variant: 'error'})
        }else
          enqueueSnackbar("Unexpected error occured while getting keywords!", {variant: 'error'})
      })
  }

  const getNext100 = async () => {
    return getAllKeywords(true);
  }

  const handleDelete = () => {
    deleteKeywordById(toBeDeleted._id, (data) => {
      dispatch(deleteKeyword(toBeDeleted));
      setToBeDeleted(null);
      enqueueSnackbar("Keyword Deleted", { variant: 'success' });
    }, (response) => {
      if (response)
        enqueueSnackbar(response.data.message, { variant: 'error' });
      else {
        enqueueSnackbar("Unexpected error occurred while deleting a keyword!", { variant: 'error' });
      }
    });
  };

  const handleFileChange = (formData) => {
    setUploading(true)

    importFile("keywords", formData)
      .then(({ data }) => {
        // console.log(data);
        enqueueSnackbar("Keywords Imported.", { variant: 'success' })
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
      .finally(() => setUploading(false));
  };

  const handleSearch = (text) => {
    dispatch(setKeywordQuery(text))
    loadKeywords();
  };

  const headerRightComponent = (
    <div className={'header-right-root'}>
      <ImportButton
        uploading={uploading}
        id={"importKeyword"}
        label="Import"
        onChange={handleFileChange}
      />

      {/* <Link >

      </Link> */}
      <Button component="a" target="blank" href={templateLink} style={{ marginLeft: '5px' }}>
        <SaveAltRounded fontSize="default" color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Download Template
          </span>
      </Button>
      <Link to={`${ADMINISTRATION}/${KEYWORD}/add`} >
        <Button>
          <AddCircle fontSize="default" color="secondary" />
          <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
            Add Keyword
          </span>
        </Button>
      </Link>
    </div>
  );
  const titleComponent = (
    <h2>Keyword List</h2>
  );
  const columns = [
    {
      key: 'name',
      name: 'Name',
      showOnMdUp: true
    },
    {
      key: 'therapeuticArea.name',
      name: 'Therapeutic Area',
      showOnMdUp: true
    },
    {
      key: 'disease.name',
      name: 'Disease'
    },
    {
      key: 'priority',
      name: 'Priority',
      renderRow: (row) => {
        switch (row.priority) {
          case 1:
            return 'Low';
          case 2:
            return 'Medium';
          case 3:
            return 'High';
          default:
            return '';
        }
      }
    }
  ];
  const rowActions = [
    {
      component: (
        <>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </>
      ),
      onClick: (row) => {
        dispatch(setActiveKeyword(row));
        props.history.push(`${path}/edit/${row._id}`);
      }
    },
    {
      component: (
        <>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </>
      ),
      onClick: (row) => {
        setToBeDeleted(row);
        setShowDeleteConfirm(true);
      }
    },
    {
      component: (row) => (
        <>
          <Typography variant="inherit">Status</Typography>
          <Switch
            checked={row.status === 'active'}
            onChange={(event) => {
              row.status = event.target.checked ? 'active' : 'inactive';
              const { _id, name, status, disease, therapeuticArea, priority } = row;
              editKeyword(_id, {
                name,
                status,
                disease: disease._id,
                therapeuticArea: therapeuticArea._id,
                priority
              }, (data) => {
                props.dispatchUpdateStatusKeyword(row);
                enqueueSnackbar(`Keyword ${row.status}!`, {
                  variant: "success",
                });
              }, ({ response }) => {
                if (response) {
                  enqueueSnackbar(response.data.message, {
                    variant: "error",
                  });
                } else {
                  enqueueSnackbar(`Error occurred while editing the keyword.`, {
                    variant: "error",
                  });
                }
              })
                .finally(() => {
                })
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <DeleteOutline fontSize="small" />
        </>
      ),
    }
  ];
  return (
    <AdminBase headerRightComponent={headerRightComponent}
      titleComponent={titleComponent}
      handleSearch={handleSearch}
      handleResetSearch={()=>handleSearch('')}
      showFilters={true}
      showSearchBox={true}>
      <GenericTable
        columns={columns}
        data={allKeywords}
        rowActions={rowActions}
        loading={loading} 
        pagination={{
          loadMore: getNext100,
          total
        }}
      />

      <ConfirmModal
        icon={PublishNewsScreenIcon}
        title="Delete Keyword"
        message="Are you sure you want to delete this keyword?"
        action="Delete"
        open={showDeleteConfirm}
        handleClose={() => setShowDeleteConfirm(false)}
        handleConfirm={handleDelete}
      />
    </AdminBase>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapActionsToProps = {
  dispatchUpdateKeyword: updateKeyword,
  dispatchUpdateStatusKeyword: updateStatusKeyword
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(Keyword));