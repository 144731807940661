import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import { AddCircle, Edit, DeleteOutline, VisibilityOutlined, VisibilityOffOutlined, SaveAltRounded } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";

import { ADMINISTRATION, COMPOUND } from '../../../constants/routes';
import AdminBase from "./AdminBase";
import GenericTable from "../../Table/genericTable";
import PublishNewsScreenIcon from "../../../assets/img/News/draftScreenIcon.svg";
import ConfirmModal from "../../Modal/ConfirmModal";

import { deleteCompoundById, editCompound, getAllCompounds } from "../../../service/compounds";
import { deleteCompound, setActiveCompound, setCompoundQuery, updateCompound } from "../../../redux/compounds/actions";
import { pick } from 'lodash';

import './styles.scss';
import ImportButton from '../../common/ImportButton';
import { importFile } from '../../../service/files';
import { getCompoundName } from '../../../helpers/utils';
import templateLink from "../../../assets/templates/Compound_Template_v2.xlsm"

const Compounds = ({ enqueueSnackbar, ...props }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { loading, allCompounds, total } = useSelector((state) => state.compounds);
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  useEffect(()=>{
    dispatch(setCompoundQuery(null))
    loadCompounds();
  }, [])

  const loadCompounds = () => {
    getAllCompounds()
      .then((data)=>{})
      .catch(({response})=>{
        if(response){
          enqueueSnackbar(response.data.message, {variant: 'error'})
        }else
          enqueueSnackbar("Unexpected error occured while getting compounds!", {variant: 'error'})
      })
  }

  const getNext100 = async () => {
    return getAllCompounds(true);
  }

  const handleDelete = () => {
    deleteCompoundById(toBeDeleted._id, (data) => {
      dispatch(deleteCompound(toBeDeleted));
      setToBeDeleted(null);
      enqueueSnackbar("Compound Deleted", { variant: 'success' });
    }, (response) => {
      if (response)
        enqueueSnackbar(response.data.message, { variant: 'error' });
      else {
        enqueueSnackbar("Unexpected error occurred while deleting a compound!", { variant: 'error' });
      }
    });
  };

  const handleSearch = (text) => {
    dispatch(setCompoundQuery(text))
    loadCompounds();
  };

  const handleResetSearch = () => {
    dispatch(setCompoundQuery(null))
    loadCompounds();
  }

  const handleFileChange = (formData) => {
    setUploading(true)
    console.log(formData)

    importFile("compounds", formData)
      .then(({ data }) => {
        // console.log(data);
        enqueueSnackbar("Compounds Imported.", { variant: 'success' })
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
      .finally(() => setUploading(false));
  };

  const headerRightComponent = (
    <div className={'header-right-root'}>
      <ImportButton
        uploading={uploading}
        id={"importCompounds"}
        label="Import"
        onChange={handleFileChange}
        fileType=".xlsm"
      />

      <Button component="a" target="blank" href={templateLink} style={{ marginLeft: '5px' }}>
        <SaveAltRounded fontSize="default" color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Download Template
        </span>
      </Button>

      <Link to={`${ADMINISTRATION}/${COMPOUND}/add`} >
        <Button>
          <AddCircle fontSize="default" color="secondary" />
          <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
            Add Compound
          </span>
        </Button>
      </Link>
    </div>
    
  );
  const titleComponent = (
    <h2>Compounds List</h2>
  );
  const columns = [
    {
      key: 'name',
      name: 'Name',
      align: 'left',
      showOnMdUp: true,
      renderRow: ({name}) => <span>{getCompoundName(name)}</span>
    },
    {
      key: 'therapeuticArea.name',
      name: 'Therapeutic Area'
    },
    {
      key: 'disease.name',
      name: 'Disease'
    },
    /*{
      key: 'activeIngredient',
      name: 'Active Ingredients',
      renderRow: (({activeIngredients}) => activeIngredients && activeIngredients.length? activeIngredients.join(', '):activeIngredients)
    },*/
    {
      key: 'therapeuticModality',
      name: 'Therapeutic Modality'
    },
    {
      key: 'status',
      name: 'Status',
      renderRow: (({status})=> <span>{status}</span>)
    },
  ];
  const rowActions = [
    {
      component: (
        <>
          <ListItemIcon>
            <Edit fontSize="small" />

          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </>
      ),
      onClick: (row) => {
        dispatch(setActiveCompound(row));
        props.history.push(`${path}/edit/${row._id}`);
      }
    },
    {
      component: (
        <>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </>
      ),
      onClick: (row) => {
        setToBeDeleted(row);
        setShowDeleteConfirm(true);
      }
    },
    {
      component: (row) => (
        <>
          <Typography variant="inherit">Status</Typography>
          <Switch
            checked={row.status.toLowerCase() === 'active'}
            onChange={(event) => {
              row.status = event.target.checked ? 'active' : 'inactive';
              const toSend = pick(row, [
                'name', 'licensee', 'originator', 
                'roa', 'mechanismOfAct',
                'compoundWebsite', 'therapeuticModality',
                'developmentPhase', 'status', 'dmt',
                'priority', 'therapeuticArea', 'disease', 'approvedInGeographies'
              ]);
              if(toSend.disease) toSend.disease = toSend.disease._id;
              if(toSend.therapeuticArea) toSend.therapeuticArea = toSend.therapeuticArea._id;

              editCompound(row._id, toSend)
                .then(({ data }) => {
                  dispatch(updateCompound(data))
                  enqueueSnackbar(`Compound ${data.status}!`, {
                    variant: "success",
                  });
                })
                .catch(({ response }) => {
                  if (response) {
                    enqueueSnackbar(response.data.message || 'error occured', {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar(`Error occurred`, {
                      variant: "error",
                    });
                  }
                })
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          {row.status.toLowerCase() === 'active' ?
            <VisibilityOutlined fontSize="small" /> :
            <VisibilityOffOutlined fontSize="small" />
          }
        </>
      ),
    }
  ];
  return (
    <AdminBase headerRightComponent={headerRightComponent}
      titleComponent={titleComponent}
      handleSearch={handleSearch}
      handleResetSearch={handleResetSearch}
      showFilters={true}
      showSearchBox={true}>
      <GenericTable
        columns={columns}
        data={allCompounds}
        rowActions={rowActions}
        loading={loading} 
        pagination={{
          loadMore: getNext100,
          total
        }}  
      />

      <ConfirmModal
        icon={PublishNewsScreenIcon}
        title="Delete Compound"
        message="Are you sure you want to delete this compound?"
        action="Delete"
        open={showDeleteConfirm}
        handleClose={() => setShowDeleteConfirm(false)}
        handleConfirm={handleDelete}
      />
    </AdminBase>
  );
};

export default withSnackbar(Compounds);